
import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebaseConfig';
import DOMPurify from 'dompurify';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import 'leaflet/dist/leaflet.css';
import { 
  FaFire, 
  FaHistory, 
  FaMapMarkedAlt, 
  FaUtensils, 
} from 'react-icons/fa';
import './BBQHistory.css';

const BBQHistory = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const handleEmailChange = (e) => {
    setEmail(e.target.value.slice(0, 100));
  };
  
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };
  
  const checkEmailExists = async (email) => {
    const q = query(collection(db, 'subscribers'), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const sanitizedEmail = DOMPurify.sanitize(email);
    
    if (!validateEmail(sanitizedEmail)) {
      setMessage('Por favor, introduce un email válido.');
      setMessageType('danger');
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      const emailExists = await checkEmailExists(sanitizedEmail);
      
      if (emailExists) {
        setMessage('Este email ya está suscrito a nuestro newsletter.');
        setMessageType('info');
        setIsSubmitting(false);
        return;
      }
      
      const subscriberData = {
        email: sanitizedEmail,
        subscriptionDate: new Date().toISOString(),
        active: true,
        source: 'historia-bbq',
        userAgent: navigator.userAgent.substring(0, 500)
      };
      
      const docRef = await addDoc(collection(db, 'subscribers'), subscriberData);
      
      const templateParams = {
        to_email: sanitizedEmail,
        reply_to: 'info@theoriginalrubhub.es'
      };
      
      await emailjs.send(
        "service_d4qn9zd",
        "template_yvv6qva",
        templateParams,
        "t4kd3w4n_cnqBTNnS"
      );
      
      setMessage('¡Gracias por suscribirte! Hemos enviado un email de confirmación a tu correo.');
      setMessageType('success');
      setEmail('');
      
    } catch (error) {
      console.error('Error al procesar la suscripción:', error);
      setMessage('Ocurrió un error al procesar tu suscripción. Por favor, inténtalo de nuevo.');
      setMessageType('danger');
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <>
      <Helmet>
        <title>Historia de la Barbacoa | Orígenes y Estilos del BBQ en Estados Unidos</title>
        <meta 
          name="description" 
          content="Descubre la fascinante historia de la barbacoa y las cuatro grandes regiones del BBQ estadounidense. Desde sus orígenes taínos hasta los estilos de Texas, Kansas City, las Carolinas y Memphis." 
        />
        <meta 
          name="keywords" 
          content="historia BBQ, orígenes barbacoa, BBQ Texas, BBQ Kansas City, BBQ Carolinas, BBQ Memphis, estilos barbacoa, tradiciones BBQ" 
        />
        <link rel="canonical" href="https://theoriginalrubhub.es/" />
        <meta property="og:title" content="Historia de la Barbacoa y las Cuatro Grandes Regiones del BBQ" />
        <meta property="og:description" content="Explora los orígenes de la barbacoa y los diferentes estilos regionales del BBQ estadounidense. Una guía completa de sabores y técnicas tradicionales." />
        <meta property="og:type" content="article" />
        <meta property="og:image" content="https://theoriginalrubhub.es/logo192.png" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            "headline": "Historia de la Barbacoa y las Cuatro Grandes Regiones del BBQ",
            "description": "Descubre los orígenes de la barbacoa y explora las cuatro grandes regiones del BBQ estadounidense: Texas, Kansas City, las Carolinas y Memphis.",
            "author": {
              "@type": "Organization",
              "name": "The Original Rub Hub",
              "url": "https://theoriginalrubhub.es"
            },
            "publisher": {
              "@type": "Organization",
              "name": "The Original Rub Hub",
              "logo": {
                "@type": "ImageObject",
                "url": "https://theoriginalrubhub.es/logo192.png"
              }
            },
            "image": "https://theoriginalrubhub.es/logo192.png",
            "datePublished": "2024-01-31",
            "dateModified": "2024-03-01",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://theoriginalrubhub.es/"
            },
            "keywords": "historia BBQ, orígenes barbacoa, BBQ Texas, BBQ Kansas City, BBQ Carolinas, BBQ Memphis, estilos barbacoa",
            "articleSection": "Historia de la Barbacoa"
          })}
        </script>
      </Helmet>

      <div className="history-hero">
        <div className="history-hero-overlay">
          <Container>
            <div className="hero-content">
              <h1>Historia de la Barbacoa</h1>
              <p className="hero-subtitle">
                Un viaje a través del tiempo y las culturas del BBQ
              </p>
              <div className="scroll-indicator">
                <span>Descubre la historia</span>
                <div className="scroll-arrow"></div>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <Container className="history-content">
        <article className="bbq-history-article">
          {/* Sección de Origen */}
          <section className="history-section origins-section" id="origenes">
            <div className="section-header">
              <div className="section-icon">
                <FaHistory />
              </div>
              <h2>El Origen de la Barbacoa: Una Tradición que Trasciende Fronteras</h2>
            </div>

            <Row className="content-row">
              <Col lg={7}>
                <div className="history-text">
                  <p className="feature-text">
                    La barbacoa es mucho más que una técnica de cocción; es una experiencia que conecta sabores, 
                    culturas y tradiciones de distintas partes del mundo. Su historia es tan rica como el aroma 
                    que desprenden las brasas, y su evolución ha sido influenciada por la creatividad y la pasión 
                    de diversas civilizaciones a lo largo de los siglos.
                  </p>

                  <div className="highlight-box">
                    <p>
                      El término "barbacoa" proviene de la palabra indígena caribeña barbacoa, que describía una 
                      estructura de madera utilizada por los pueblos taínos para cocinar sobre el fuego.
                    </p>
                  </div>

                  <p>
                    Sin embargo, el concepto fue rápidamente adoptado y transformado por diferentes culturas en 
                    América Latina, Estados Unidos y el Caribe, desarrollando estilos únicos como la barbacoa texana, 
                    el asado argentino o el barbecue estadounidense, cada uno con técnicas, adobos y sabores propios.
                  </p>

                  <div className="blog-invitation">
                    <p>
                      En este blog, te invitamos a sumergirte en el fascinante mundo de la barbacoa, donde 
                      exploraremos las raíces históricas y culturales, compartiremos recetas tradicionales y 
                      modernas, adobos irresistibles, y útiles imprescindibles para disfrutar de esta experiencia 
                      en su máxima expresión.
                    </p>
                    <p>
                      Desde cómo elegir la mejor parrilla hasta los secretos de los ahumados y marinados perfectos, 
                      aquí encontrarás todo lo que necesitas para dominar el arte de la barbacoa y hacer de cada 
                      comida al aire libre una celebración.
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={5}>
                <div className="history-image-container">
                  <div className="history-image origin-image">
                    <div className="image-placeholder">
                        <img src="/taina.webp" alt="Estructura de barbacoa taína tradicional" />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </section>

          {/* Sección de Regiones */}
          <section className="history-section regions-section" id="regiones">
            <div className="section-header">
              <div className="section-icon">
                <FaMapMarkedAlt />
              </div>
              <h2>Las Cuatro Grandes Regiones del Barbecue en Estados Unidos</h2>
            </div>

            <div className="regions-intro">
              <p className="feature-text">
                El barbecue en Estados Unidos no es solo una forma de cocinar, sino una tradición profundamente 
                enraizada que varía de una región a otra. Las cuatro grandes regiones del barbecue estadounidense 
                —Texas, Kansas City, Carolina del Norte y Memphis— han desarrollado sus propios estilos, cada uno 
                con técnicas, sabores y cortes de carne específicos.
              </p>
            </div>

            <div className="regions-map-container">
              <div className="static-map-image">
                <img 
                  src="mapa.PNG" 
                  alt="Mapa de regiones BBQ" 
                  className="w-full h-auto" 
                />
              </div>
            </div>

            {/* Region: Texas */}
            <div className="region-block texas-block">
              <div className="region-header">
                <span className="region-number">01</span>
                <h3>Barbecue de Texas: Una Pasión que Varía entre el Este, Centro y Sur</h3>
              </div>

              <Row className="region-content">
                <Col lg={7}>
                  <div className="region-text">
                    <p className="region-intro">
                      Texas es inmensa, y eso se refleja en la diversidad de estilos de barbecue que encontramos 
                      dentro de su territorio. Aunque el brisket sigue siendo el rey, cada subregión aporta su 
                      toque especial a esta tradición.
                    </p>

                    <div className="subregions">
                      <div className="subregion">
                        <h4>
                          <FaFire className="subregion-icon" />
                          Este de Texas
                        </h4>
                        <p>
                          Influenciado por el estilo sureño, el barbecue del este de Texas se caracteriza por el 
                          uso de salsas espesas y dulces a base de tomate. Las carnes suelen cocinarse hasta alcanzar 
                          una textura tan tierna que se desmenuzan fácilmente. En esta zona, es común ver tanto cerdo 
                          como res, y el cerdo desmenuzado, o pulled pork, es una especialidad. Los ahumadores de leña 
                          de nogal y nogal americano son los más utilizados para intensificar el sabor.
                        </p>
                      </div>

                      <div className="subregion">
                        <h4>
                          <FaFire className="subregion-icon" />
                          Centro de Texas
                        </h4>
                        <p>
                          Esta subregión es el corazón del barbecue texano. Conocido por su simplicidad, el estilo 
                          del centro de Texas utiliza un dry rub de sal y pimienta negra para realzar el sabor del 
                          brisket y las costillas. Aquí, la carne es la protagonista, sin salsas que distraigan de 
                          su sabor ahumado. Se ahúma principalmente con madera de roble, lo que le da un toque suave 
                          y a la vez complejo. Además del brisket, las salchichas son un plato muy popular en esta zona.
                        </p>
                      </div>

                      <div className="subregion">
                        <h4>
                          <FaFire className="subregion-icon" />
                          Sur de Texas
                        </h4>
                        <p>
                          Influenciado por la cultura mexicana, el barbecue en el sur de Texas incluye sabores únicos 
                          y técnicas como la "barbacoa de pozo" (o barbacoa de hoyo), en la cual la carne, típicamente 
                          de cabeza de res o cabrito, se cocina lentamente en un pozo bajo tierra, logrando una textura 
                          extremadamente tierna y un sabor profundo. Este estilo suele acompañarse con tortillas, salsas 
                          picantes y otros ingredientes inspirados en la cocina mexicana.
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={5}>
                  <div className="region-images">
                    <div className="main-image">
                      <div className="image-placeholder">
                        <img src="/brisket.webp" alt="Brisket Texano" />
                      </div>
                    </div>
                    <div className="secondary-images">
                      <div className="image-placeholder">
                        <img src="/bbq-pozo.png" alt="Barbacoa de Pozo" />
                      </div>
                      <div className="image-placeholder">
                        <img src="/ahumador.webp" alt="Ahumador Tradicional" />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {/* Region: Kansas City */}
            <div className="region-block kansas-block">
              <div className="region-header">
                <span className="region-number">02</span>
                <h3>Barbecue de Kansas City: El Arte de la Variedad</h3>
              </div>

              <Row className="region-content">
                <Col lg={7}>
                  <div className="region-text">
                    <p className="feature-text">
                      Kansas City es conocido por su enfoque versátil en el barbecue. En esta región, se utilizan 
                      diferentes tipos de carne, desde costillas de cerdo y res hasta pollo y burnt ends, los 
                      extremos caramelizados del brisket.
                    </p>

                    <div className="highlight-box">
                      <h4>Características Distintivas</h4>
                      <p>
                        La famosa salsa de barbecue de Kansas City es espesa y dulce, a base de tomate y melaza, 
                        lo que le aporta un sabor agridulce único que complementa el ahumado de las carnes. Aquí, 
                        el enfoque está en combinar un dry rub con salsas que realzan la experiencia, y se usa 
                        madera de nogal para añadir un toque de robustez en cada bocado.
                      </p>
                    </div>

                    <div className="specialty-section">
                      <h4>Especialidad: Burnt Ends</h4>
                      <p>
                        Los burnt ends son una especialidad local que ha ganado reconocimiento internacional. 
                        Estos extremos caramelizados del brisket ofrecen una explosión de sabores y texturas 
                        que representa perfectamente el estilo de Kansas City.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={5}>
                  <div className="region-images">
                    <div className="main-image">
                      <div className="image-placeholder">
                        <img src="/burns.webp" alt="Burnt Ends de Kansas City" />
                      </div>
                    </div>
                    <div className="sauce-showcase">
                      <div className="image-placeholder">
                        <img src="/salsas-kc.webp" alt="Salsas Tradicionales KC" />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            {/* Region: Carolinas */}
            <div className="region-block carolinas-block">
              <div className="region-header">
                <span className="region-number">03</span>
                <h3>Barbecue de las Carolinas: Tradición Dividida entre Noreste, Centro-Oeste y Sur</h3>
              </div>

              <Row className="region-content">
                <Col lg={7}>
                  <div className="region-text">
                    <p className="feature-text">
                      La región de las Carolinas es el hogar del barbecue de cerdo entero, cocido lentamente a 
                      fuego bajo y generalmente acompañado de una salsa a base de vinagre.
                    </p>

                    <div className="subregions">
                      <div className="subregion">
                        <h4>
                          <FaFire className="subregion-icon" />
                          Noreste de Carolina del Norte
                        </h4>
                        <p>
                          En esta zona, el cerdo entero es la estrella y se cocina lentamente hasta que la carne 
                          está lo suficientemente tierna para ser desmenuzada o picada. La salsa aquí es completamente 
                          a base de vinagre, con un toque de sal y hojuelas de chile para realzar el sabor natural 
                          del cerdo sin añadir dulzura. Este estilo es uno de los más rústicos y simples, y refleja 
                          la autenticidad del barbecue tradicional.
                        </p>
                      </div>

                      <div className="subregion">
                        <h4>
                          <FaFire className="subregion-icon" />
                          Centro-Oeste de Carolina del Norte (Estilo Lexington)
                        </h4>
                        <p>
                          También conocido como estilo de Lexington o estilo Piedmont, esta subregión añade tomate 
                          a la salsa de vinagre, logrando un sabor ligeramente más dulce que el estilo noreste. 
                          Esta salsa se usa tanto para aderezar el cerdo desmenuzado como para acompañar costillas 
                          y otras piezas de cerdo. En esta región, es común el uso de leña de nogal para un ahumado 
                          suave y profundo.
                        </p>
                      </div>

                      <div className="subregion">
                        <h4>
                          <FaFire className="subregion-icon" />
                          Sur de Carolina del Sur
                        </h4>
                        <p>
                          Aquí, el barbecue se caracteriza por una salsa amarilla a base de mostaza, vinagre, 
                          azúcar y especias, conocida como "salsa dorada". Este toque distintivo de mostaza le da 
                          al barbecue de Carolina del Sur un sabor agridulce y picante, único en la región. Aunque 
                          el cerdo sigue siendo la carne principal, en esta zona también se preparan costillas y 
                          otros cortes, siempre con el toque característico de la salsa dorada.
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={5}>
                  <div className="region-images">
                    <div className="main-image">
                      <div className="image-placeholder">
                        <img src="/pullet.webp" alt="Pulled Pork Carolinas" />
                        <span>Pulled Pork Carolinas</span>
                      </div>
                    </div>
                    <div className="sauces-comparison">
                      <div className="image-placeholder">
                        <img src="/tres-salsas.webp" alt="Las Tres Salsas Tradicionales" />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            {/* Region: Memphis */}
            <div className="region-block memphis-block">
              <div className="region-header">
                <span className="region-number">04</span>
                <h3>Barbecue de Memphis: La Cuna de las Costillas</h3>
              </div>

              <Row className="region-content">
                <Col lg={7}>
                  <div className="region-text">
                    <p className="feature-text">
                      Memphis es reconocida mundialmente por sus costillas, cocinadas en dos estilos: secas (dry) 
                      y mojadas (wet).
                    </p>

                    <div className="styles-comparison">
                      <div className="style-box">
                        <h4>Costillas Secas (Dry Ribs)</h4>
                        <p>
                          Las dry ribs se sazonan con una mezcla de especias (dry rub) que incluye pimentón, 
                          comino y ajo, y se ahúman hasta obtener una textura tierna pero sin necesidad de salsa.
                        </p>
                      </div>

                      <div className="style-box">
                        <h4>Costillas Mojadas (Wet Ribs)</h4>
                        <p>
                          Las wet ribs se bañan en salsa de barbecue durante la cocción, resultando en una capa 
                          jugosa y caramelizada.
                        </p>
                      </div>
                    </div>

                    <div className="additional-info">
                      <p>
                        Además de las costillas, en Memphis también es común el pulled pork, servido en sándwich 
                        y acompañado de una salsa ligera a base de tomate y vinagre que equilibra la grasa y 
                        realza el sabor ahumado.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={5}>
                  <div className="region-images">
                    <div className="main-image">
                      <div className="image-placeholder">
                        <img src="/costillar.webp" alt="Costillas Estilo Memphis" />
                      </div>
                    </div>
                    <div className="styles-showcase">
                      <div className="image-placeholder">
                        <img src="/dry-vs-wet.webp" alt="Dry vs Wet Ribs" />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

          </section>

          {/* Newsletter Section */}
          <section className="newsletter-section">
            <div className="section-icon">
              <FaUtensils />
            </div>
            <Row className="justify-content-center">
              <Col md={8}>
                <div className="newsletter-container">
                  <h3>Únete a Nuestra Comunidad BBQ</h3>
                  <p>
                    Con esta guía completa, te invitamos a descubrir el sabor único de cada región y subregión 
                    de barbecue en Estados Unidos. Suscríbete para recibir más contenido sobre recetas auténticas, 
                    técnicas tradicionales y secretos de cada estilo.
                  </p>
                  
                  {message && (
                    <Alert variant={messageType} onClose={() => setMessage('')} dismissible>
                      {message}
                    </Alert>
                  )}
                  
                  <form className="newsletter-form" onSubmit={handleSubmit}>
                    <input 
                      type="email" 
                      placeholder="Tu correo electrónico" 
                      aria-label="Email para suscripción"
                      required 
                      value={email}
                      onChange={handleEmailChange}
                      maxLength={100}
                    />
                    <button 
                      type="submit" 
                      className="btn-subscribe"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Procesando...' : 'Suscribirse'}
                    </button>
                  </form>
                </div>
              </Col>
            </Row>
          </section>
        </article>
      </Container>
    </>
  );
};

export default BBQHistory;
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { recipeService } from '../../services/recipeService';
import { tipService } from '../../services/tipService';
import { authService } from '../../services/authService';
import NotifyPostsButton from './NotifyPostsButton';

const AdminDashboard = () => {
  const [stats, setStats] = useState({
    totalRecipes: 0,
    totalTips: 0,
    recentRecipes: [],
    recentTips: []
  });

  useEffect(() => {
    const loadDashboardData = async () => {
      try {
        const [recipes, tips] = await Promise.all([
          recipeService.getPaginatedRecipes(1, 5),
          tipService.getPaginatedTips(1, 5)
        ]);

        setStats({
          totalRecipes: recipes.total,
          totalTips: tips.total,
          recentRecipes: recipes.recipes,
          recentTips: tips.tips
        });
      } catch (error) {
        console.error('Error cargando datos del dashboard:', error);
      }
    };

    loadDashboardData();
  }, []);

  return (
    <div className="admin-dashboard">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Panel de Administración</h1>
        <NotifyPostsButton />
        <div>
          <small className="text-muted me-3">
            Usuario: {authService.getCurrentUser()?.email}
          </small>
        </div>
      </div>

      <Row className="g-4 mb-4">
        <Col md={6}>
          <Card className="h-100">
            <Card.Body>
              <Card.Title>Recetas</Card.Title>
              <Card.Text>Total de recetas: {stats.totalRecipes}</Card.Text>
              <Link to="/admin/recipe/new">
                <Button variant="primary">Nueva Receta</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="h-100">
            <Card.Body>
              <Card.Title>Tips y Consejos</Card.Title>
              <Card.Text>Total de tips: {stats.totalTips}</Card.Text>
              <Link to="/admin/tip/new">
                <Button variant="primary">Nuevo Tip</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="g-4">
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Últimas Recetas</Card.Title>
              <div className="list-group">
                {stats.recentRecipes.map(recipe => (
                  <Link
                    key={recipe.id}
                    to={`/admin/recipe/${recipe.id}`}
                    className="list-group-item list-group-item-action"
                  >
                    {recipe.title}
                  </Link>
                ))}
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Últimos Tips</Card.Title>
              <div className="list-group">
                {stats.recentTips.map(tip => (
                  <Link
                    key={tip.id}
                    to={`/admin/tip/${tip.id}`}
                    className="list-group-item list-group-item-action"
                  >
                    {tip.title}
                  </Link>
                ))}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AdminDashboard;
import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs, getDoc, query, orderBy, limit, serverTimestamp, where, startAfter } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { imageService } from './imageService';
import slugify from 'slugify';

const COLLECTION_NAME = 'recipes';

export const recipeService = {
  async getAllRecipes() {
    const querySnapshot = await getDocs(collection(db, COLLECTION_NAME));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  async getPaginatedRecipes(page, perPage, searchTerm = '', category = '', subcategory = '') {
    const recipesRef = collection(db, COLLECTION_NAME);
    let q = query(recipesRef, orderBy('createdAt', 'desc'));
    
    const constraints = [];

    if (searchTerm) {
      const endString = searchTerm + '\uf8ff';
      constraints.push(
        where('title', '>=', searchTerm), 
        where('title', '<=', endString)
      );
    }

    if (category) {
      constraints.push(where('category', '==', category));
    }

    if (subcategory) {
      constraints.push(where('subcategory', '==', subcategory));
    }

    if (constraints.length > 0) {
      q = query(q, ...constraints);
    }

    const startIndex = (page - 1) * perPage;
    q = query(q, limit(perPage));

    const snapshot = await getDocs(q);
    const recipes = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    const totalQuery = query(recipesRef, ...constraints);
    const totalSnapshot = await getDocs(totalQuery);
    const total = totalSnapshot.size;

    return { recipes, total };
  },

  async getInitialRecipes(limitCount = 100) {
    const recipesRef = collection(db, COLLECTION_NAME);
    const q = query(
      recipesRef,
      orderBy('createdAt', 'desc'),
      limit(limitCount)
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  async getMoreRecipes(lastRecipe, limitCount = 20) {
    const recipesRef = collection(db, COLLECTION_NAME);
    const q = query(
      recipesRef,
      orderBy('createdAt', 'desc'),
      startAfter(lastRecipe.createdAt),
      limit(limitCount)
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  async getRecipeById(id) {
    const docRef = doc(db, COLLECTION_NAME, id);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? { id: docSnap.id, ...docSnap.data() } : null;
  },

  async getRecipeBySlug(slug) {
    const q = query(collection(db, COLLECTION_NAME), where("slug", "==", slug));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      return { id: doc.id, ...doc.data() };
    }
    return null;
  },

  async createRecipe(recipeData) {
    const { mainImage, steps, title, ...rest } = recipeData;
    
    const slug = slugify(title, { lower: true, strict: true });
    
    const mainImageUrl = await imageService.uploadAndOptimizeImage(mainImage, 'main', title);

    const updatedSteps = await Promise.all(steps.map(async (step, index) => {
      if (step.image) {
        const imageUrl = await imageService.uploadAndOptimizeImage(step.image, `step_${index}`, `${title} - Step ${index + 1}`);
        return { ...step, image: imageUrl };
      }
      return step;
    }));

    const formattedIngredients = rest.ingredients.map(ingredient => ({
      quantity: ingredient.quantity || '',
      unit: ingredient.unit || '',
      name: ingredient.name || ''
    }));

    const structuredData = this.generateStructuredData({ 
      ...rest, 
      title, 
      mainImage: mainImageUrl, 
      steps: updatedSteps 
    });

    const newRecipe = { 
      ...rest, 
      title,
      slug,
      rating: Number(rest.rating) || 5,
      mainImage: mainImageUrl, 
      steps: updatedSteps,
      ingredients: formattedIngredients,
      structuredData,
      metaTitle: title,
      metaDescription: rest.description.substring(0, 160),
      keywords: this.generateKeywords(title, formattedIngredients.map(ing => ing.name)),
      datePublished: new Date().toISOString(),
      dateModified: new Date().toISOString(),
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      isReported: false,
      reportedAt: null 
    };

    const docRef = await addDoc(collection(db, COLLECTION_NAME), newRecipe);
    return { id: docRef.id, ...newRecipe };
  },

  async updateRecipe(id, recipeData) {
    const oldRecipe = await this.getRecipeById(id);
    const { mainImage, steps, title, ...rest } = recipeData;
    
    const slug = slugify(title, { lower: true, strict: true });

    // Manejo de la imagen principal
    let mainImageUrl = oldRecipe.mainImage;
    if (mainImage instanceof File) {
      mainImageUrl = await imageService.uploadAndOptimizeImage(mainImage, 'recipes', title);
    }

    // Manejo de las imágenes de los pasos
    const stepsWithImages = await Promise.all(steps.map(async (step, index) => {
      if (step.image instanceof File) {
        const imageUrl = await imageService.uploadAndOptimizeImage(step.image, 'recipe-steps', `${title} - Paso ${index + 1}`);
        return { ...step, image: imageUrl };
      }
      return { ...step, image: oldRecipe.steps[index]?.image || step.image };
    }));

    // Formateamos los ingredientes
    const formattedIngredients = rest.ingredients.map(ingredient => ({
      quantity: ingredient.quantity || '',
      unit: ingredient.unit || '',
      name: ingredient.name || ''
    }));

    const structuredData = this.generateStructuredData({ 
      ...rest, 
      title, 
      mainImage: mainImageUrl, 
      steps: stepsWithImages 
    });

    const updatedRecipe = { 
      ...rest, 
      title,
      slug,
      rating: Number(rest.rating) || oldRecipe.rating || 5,
      mainImage: mainImageUrl, 
      steps: stepsWithImages,
      ingredients: formattedIngredients,
      structuredData,
      metaTitle: rest.metaTitle || title,
      metaDescription: rest.metaDescription || rest.description.substring(0, 160),
      keywords: rest.keywords || this.generateKeywords(title, formattedIngredients.map(ing => ing.name)),
      updatedAt: serverTimestamp(),
    };

    await updateDoc(doc(db, COLLECTION_NAME, id), updatedRecipe);
    return { id, ...updatedRecipe };
  },

  async deleteRecipe(id) {
    try {
      const recipe = await this.getRecipeById(id);
      if (!recipe) return false;
  
      // Eliminar imágenes
      const deletePromises = [];
  
      // Eliminar imagen principal
      if (recipe.mainImage) {
        deletePromises.push(imageService.deleteImage(recipe.mainImage));
      }
  
      // Eliminar imágenes de los pasos
      if (recipe.steps && Array.isArray(recipe.steps)) {
        recipe.steps.forEach(step => {
          if (step.image) {
            deletePromises.push(imageService.deleteImage(step.image));
          }
        });
      }
  
      // Esperar a que se eliminen todas las imágenes
      await Promise.all(deletePromises);
  
      // Eliminar el documento de la receta
      await deleteDoc(doc(db, COLLECTION_NAME, id));
      return true;
  
    } catch (error) {
      console.error('Error deleting recipe:', error);
      throw error;
    }
  },

  generateStructuredData(recipe) {
    const ingredients = recipe.ingredients.map(ing => 
      `${ing.quantity} ${ing.unit} ${ing.name}`.trim()
    );

    return {
      "@context": "https://schema.org/",
      "@type": "Recipe",
      "name": recipe.title,
      "image": recipe.mainImage.url,
      "description": recipe.description,
      "recipeIngredient": ingredients,
      "recipeInstructions": recipe.steps.map(step => step.description)
    };
  },

  generateKeywords(title, ingredients) {
    const titleWords = title.toLowerCase().split(' ');
    const ingredientWords = ingredients.flatMap(i => i.toLowerCase().split(' '));
    const allWords = [...new Set([...titleWords, ...ingredientWords])];
    return allWords.filter(word => word.length > 3).slice(0, 10);
  },

  getMeasurementUnits() {
    return [
      // Volumen
      { value: 'ml', label: 'Mililitros (ml)' },
      { value: 'l', label: 'Litros (l)' },
      { value: 'taza', label: 'Taza' },
      { value: 'cdta', label: 'Cucharadita' },
      { value: 'cda', label: 'Cucharada' },
      { value: 'pizca', label: 'Pizca' },
      { value: 'taza_americana', label: 'Taza americana' },
      { value: 'fl_oz', label: 'Onza líquida (fl oz)' },
      { value: 'gal', label: 'Galón' },
      
      // Peso
      { value: 'g', label: 'Gramos (g)' },
      { value: 'kg', label: 'Kilogramos (kg)' },
      { value: 'lb', label: 'Libras (lb)' },
      { value: 'oz', label: 'Onzas (oz)' },
      
      // Unidades
      { value: 'unidad', label: 'Unidad' },
      { value: 'docena', label: 'Docena' },
      { value: 'pieza', label: 'Pieza' },
      { value: 'rebanada', label: 'Rebanada' },
      { value: 'trozo', label: 'Trozo' },
      
      // Medidas específicas de cocina
      { value: 'al_gusto', label: 'Al gusto' },
      { value: 'paquete', label: 'Paquete' },
      { value: 'lata', label: 'Lata' },
      { value: 'manojo', label: 'Manojo' },
      { value: 'diente', label: 'Diente (ajo)' },
      { value: 'rama', label: 'Rama' },
      { value: 'rodaja', label: 'Rodaja' },
      { value: 'tallo', label: 'Tallo' },
      { value: 'hoja', label: 'Hoja' }
    ];
  },

  getCategories() {
    return [
      {
        id: 'recetas-bbq',
        name: "Recetas BBQ",
        icon: "FaHamburger",
        color: "#FF6B6B",
        subcategories: [
          { id: 'carnes', name: "Carnes", icon: "FaHamburger" },
          { id: 'pescados-mariscos', name: "Pescados y Mariscos", icon: "FaFish" },
          { id: 'vegetarianos', name: "Vegetarianos", icon: "FaCarrot" },
          { id: 'hamburguesas-perros', name: "Hamburguesas y Perros Calientes", icon: "FaHamburger" },
          { id: 'acompañamientos', name: "Acompañamientos", icon: "FaUtensils" },
          { id: 'postres-grill', name: "Postres al Grill", icon: "FaFireAlt" }
        ]
      },
      {
        id: 'cortes-carne',
        name: "Cortes de Carne",
        icon: "FaUtensils",
        color: "#4ECDC4",
        subcategories: [
          { id: 'cortes-res', name: "Cortes de Res", icon: "FaHamburger" },
          { id: 'cortes-cerdo', name: "Cortes de Cerdo", icon: "FaHamburger" },
          { id: 'cortes-pollo', name: "Cortes de Pollo", icon: "FaDrumstickBite" },
          { id: 'cortes-mariscos', name: "Mariscos y Pescados", icon: "FaFish" }
        ]
      },
      {
        id: 'salsas-marinadas',
        name: "Salsas y Marinadas",
        icon: "FaWineBottle",
        color: "#45B7D1",
        subcategories: [
          { id: 'salsas-bbq', name: "Salsas BBQ", icon: "FaWineBottle" },
          { id: 'marinadas-rubs', name: "Marinadas y Rubs", icon: "FaWineBottle" },
          { id: 'dips-acompañamientos', name: "Dips y acompañamientos", icon: "FaWineBottle" }
        ]
      },
      {
        id: 'tecnicas-bbq',
        name: "Técnicas BBQ",
        icon: "FaFireAlt",
        color: "#FFA5AB",
        subcategories: [
          { id: 'ahumado', name: "Ahumado", icon: "FaSmog" },
          { id: 'tiempos-temperaturas', name: "Tiempos y Temperaturas", icon: "FaFireAlt" },
          { id: 'control-fuego', name: "Control del Fuego", icon: "FaFireAlt" },
          { id: 'herramientas-parrillero', name: "Herramientas del Parrillero", icon: "FaUtensils" }
        ]
      },
      {
        id: 'estilos-bbq',
        name: "Estilos BBQ",
        icon: "FaUtensils",
        color: "#6E7DAB",
        subcategories: [
          { id: 'estilo-americano', name: "Estilo Americano", icon: "FaHamburger" },
          { id: 'asado-argentino', name: "Asado Argentino", icon: "FaFireAlt" },
          { id: 'churrasco-brasileño', name: "Churrasco Brasileño", icon: "FaFireAlt" },
          { id: 'otros-estilos', name: "Otros Estilos Internacionales", icon: "FaUtensils" }
        ]
      }
    ];
  }
};
import React, { useState } from 'react';
import { Button, Modal, Spinner, Alert } from 'react-bootstrap';
import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../services/firebaseConfig';
import emailjs from '@emailjs/browser';

const NotifyPostsButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [result, setResult] = useState({ status: '', message: '', details: [] });

  const handleNotifyPosts = async () => {
    setIsLoading(true);
    setResult({ status: '', message: '', details: [] });
    setShowModal(true);
    
    try {
      // 1. Obtener la fecha actual en formato YYYY-MM-DD
      const today = new Date().toISOString().split('T')[0];
      
      // 2. Buscar posts no informados (recipes y tips)
      const newPosts = [];
      
      // Buscar en recipes
      const recipesQuery = query(
        collection(db, 'recipes'),
        where('publishDate', '<=', today),
        where('isReported', '!=', true)
      );
      const recipesSnapshot = await getDocs(recipesQuery);
      
      recipesSnapshot.forEach(doc => {
        newPosts.push({
          id: doc.id,
          collection: 'recipes',
          type: 'receta',
          ...doc.data()
        });
      });
      
      // Buscar en tips
      const tipsQuery = query(
        collection(db, 'tips'),
        where('publishDate', '<=', today),
        where('isReported', '!=', true)
      );
      const tipsSnapshot = await getDocs(tipsQuery);
      
      tipsSnapshot.forEach(doc => {
        newPosts.push({
          id: doc.id,
          collection: 'tips',
          type: 'consejo',
          ...doc.data()
        });
      });
      
      // 3. Verificar si hay posts para informar
      if (newPosts.length === 0) {
        setResult({
          status: 'warning',
          message: 'No hay nuevos posts para informar.'
        });
        setIsLoading(false);
        return;
      }
      
      // 4. Obtener suscriptores
      const subscribersQuery = query(
        collection(db, 'subscribers'),
        where('active', '==', true)
      );
      const subscribersSnapshot = await getDocs(subscribersQuery);
      
      if (subscribersSnapshot.empty) {
        setResult({
          status: 'warning',
          message: 'No hay suscriptores activos para informar.'
        });
        setIsLoading(false);
        return;
      }
      
      // 5. Obtener algunos posts para la sección "También podría interesarte"
      const relatedPostsQuery = query(
        collection(db, 'recipes'),
        where('isReported', '==', true),
        where('publishDate', '<=', today)
      );
      const relatedPostsSnapshot = await getDocs(relatedPostsQuery);
      
      const relatedPosts = [];
      relatedPostsSnapshot.forEach(doc => {
        const data = doc.data();
        relatedPosts.push({
          id: doc.id,
          title: data.title,
          slug: data.slug,
          collection: 'recipes'
        });
      });
      
      // 6. Enviar emails
      const subscribers = [];
      subscribersSnapshot.forEach(doc => {
        subscribers.push(doc.data());
      });
      
      const notificationResults = [];
      
      for (const post of newPosts) {
        // Crear el HTML para posts relacionados (máximo 3)
        const relatedPostsHTML = relatedPosts
          .filter(related => related.id !== post.id)
          .slice(0, 3)
          .map(related => {
            const postUrl = `https://theoriginalrubhub.es/${related.collection === 'recipes' ? 'receta' : 'consejo'}/${related.slug}`;
            return `<li><a href="${postUrl}">${related.title}</a></li>`;
          })
          .join('');
        
        // Enviar email a cada suscriptor
        const postData = {
          post_type: post.type,
          post_title: post.title,
          post_image: post.mainImage?.url || post.image?.url,
          post_date: new Date(post.publishDate).toLocaleDateString('es-ES'),
          post_category: post.category || '',
          post_excerpt: post.description?.substring(0, 150) + '...' || post.content?.substring(0, 150) + '...',
          post_url: `https://theoriginalrubhub.es/${post.collection === 'recipes' ? 'receta' : 'consejo'}/${post.slug}`,
          related_posts: relatedPostsHTML
        };
        
        let emailsSent = 0;
        
        for (const subscriber of subscribers) {
          try {
            // Preparar parámetros para EmailJS
            const emailParams = {
              ...postData,
              to_email: subscriber.email
            };
            
            // Enviar email con EmailJS
            await emailjs.send(
              "service_d4qn9zd",
              "template_kixw1wt",
              emailParams,
              "t4kd3w4n_cnqBTNnS"
            );
            
            emailsSent++;
          } catch (error) {
            console.error('Error al enviar email:', error);
          }
        }
        
        // Marcar el post como informado
        await updateDoc(doc(db, post.collection, post.id), {
          isReported: true,
          reportedAt: new Date().toISOString()
        });
        
        notificationResults.push({
          postTitle: post.title,
          emailsSent,
          totalSubscribers: subscribers.length
        });
      }
      
      // 7. Mostrar resultado
      setResult({
        status: 'success',
        message: `Se han enviado notificaciones para ${newPosts.length} posts.`,
        details: notificationResults
      });
      
    } catch (error) {
      console.error('Error en proceso de notificación:', error);
      setResult({
        status: 'danger',
        message: 'Ha ocurrido un error durante el proceso de notificación.',
        details: [{ error: error.message }]
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button 
        variant="info" 
        className="d-flex align-items-center" 
        onClick={handleNotifyPosts}
        disabled={isLoading}
      >
        {isLoading ? (
          <Spinner animation="border" size="sm" className="me-2" />
        ) : (
          <i className="bi bi-bell-fill me-2"></i>
        )}
        Notificar Nuevos Posts
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Notificación de Nuevos Posts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div className="text-center my-4">
              <Spinner animation="border" />
              <p className="mt-3">Procesando notificaciones...</p>
            </div>
          ) : (
            <>
              {result.status && (
                <Alert variant={result.status}>
                  {result.message}
                </Alert>
              )}
              
              {result.details && result.details.length > 0 && (
                <div className="mt-3">
                  <h6>Detalles:</h6>
                  <ul className="list-group">
                    {result.details.map((detail, index) => (
                      <li key={index} className="list-group-item">
                        <strong>{detail.postTitle}</strong>
                        <div>Emails enviados: {detail.emailsSent} / {detail.totalSubscribers}</div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NotifyPostsButton;
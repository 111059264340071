import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getAllPosts } from '../../services/postService';
import emailjs from '@emailjs/browser';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebaseConfig';
import DOMPurify from 'dompurify';
import './Blog.css';

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const fetchedPosts = await getAllPosts();
        const madridDate = new Date().toLocaleString('en-US', { 
          timeZone: 'Europe/Madrid' 
        });
        const today = new Date(madridDate).toISOString().split('T')[0];
        
        const publishedPosts = fetchedPosts.filter(post => {
          return post.publishDate && post.publishDate <= today;
        });
        const sortedPosts = publishedPosts.sort((a, b) => 
          b.publishDate.localeCompare(a.publishDate)
        );
        setPosts(sortedPosts);
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchPosts();
  }, []);

  const getPostRoute = (post) => {
    if (post.collection === 'recipes') return `/receta/${post.slug}`;
    if (post.collection === 'tips') return `/consejo/${post.slug}`;
    return '/blog';
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('es-ES', options);
  };

  const handleEmailChange = (e) => {
    // Limita la longitud del email para prevenir ataques
    setEmail(e.target.value.slice(0, 100));
  };

  const validateEmail = (email) => {
    // Validación básica de email
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const checkEmailExists = async (email) => {
    // Verifica si el email ya está registrado
    const q = query(collection(db, 'subscribers'), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Limpia el email usando DOMPurify
    const sanitizedEmail = DOMPurify.sanitize(email);
    
    // Validación adicional
    if (!validateEmail(sanitizedEmail)) {
      setMessage('Por favor, introduce un email válido.');
      setMessageType('danger');
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      // Verificar si el email ya existe
      const emailExists = await checkEmailExists(sanitizedEmail);
      
      if (emailExists) {
        setMessage('Este email ya está suscrito a nuestro newsletter.');
        setMessageType('info');
        setIsSubmitting(false);
        return;
      }
      
      // 1. Guardar el suscriptor en Firebase con datos sanitizados
      const subscriberData = {
        email: sanitizedEmail,
        subscriptionDate: new Date().toISOString(),
        active: true,
        source: 'blog',
        userAgent: navigator.userAgent.substring(0, 500) // Limitar longitud
      };
      
      const docRef = await addDoc(collection(db, 'subscribers'), subscriberData);
      
      // 2. Enviar email de confirmación con EmailJS
      const templateParams = {
        to_email: sanitizedEmail,
        reply_to: 'info@theoriginalrubhub.es'
      };
      
      await emailjs.send(
        "service_d4qn9zd",
        "template_yvv6qva",
        templateParams,
        "t4kd3w4n_cnqBTNnS"
      );
      
      // 3. Mostrar mensaje de éxito y limpiar el formulario
      setMessage('¡Gracias por suscribirte! Hemos enviado un email de confirmación a tu correo.');
      setMessageType('success');
      setEmail('');
      
    } catch (error) {
      console.error('Error al procesar la suscripción:', error);
      setMessage('Ocurrió un error al procesar tu suscripción. Por favor, inténtalo de nuevo.');
      setMessageType('danger');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Blog de Barbacoa | Recetas, Técnicas y Consejos para Dominar el BBQ</title>
        <meta 
          name="description" 
          content="Aprende todo sobre el arte de la barbacoa en nuestro blog especializado: recetas auténticas, técnicas de cocción, selección de equipos, marinados y rubs caseros. Contenido para principiantes y expertos en parrilla." 
        />
        <meta 
          name="keywords" 
          content="blog barbacoa, recetas BBQ, técnicas parrilla, ahumado carnes, rubs caseros, marinados BBQ, cortes para asar, equipos barbacoa, consejos pitmaster, temperaturas cocción, barbacoa perfecta, ahumadores, salsas BBQ, parrilla carbón, BBQ americano" 
        />
        <link rel="canonical" href="https://theoriginalrubhub.es/blog" />
        <meta property="og:title" content="Blog de Barbacoa | Domina el Arte del BBQ" />
        <meta property="og:description" content="Explora nuestro blog especializado en barbacoa y descubre recetas auténticas, consejos de expertos, guías de equipamiento y todo lo necesario para convertirte en un maestro de la parrilla." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://theoriginalrubhub.es/logo192.png" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Blog",
            "headline": "Blog de Barbacoa - Descubre el Arte del BBQ",
            "description": "Explora nuestro blog especializado en barbacoa: recetas, consejos, técnicas y historia del BBQ. Todo lo que necesitas saber sobre el arte de la parrilla.",
            "author": {
              "@type": "Organization",
              "name": "The Original Rub Hub",
              "url": "https://theoriginalrubhub.es"
            },
            "publisher": {
              "@type": "Organization",
              "name": "The Original Rub Hub",
              "logo": {
                "@type": "ImageObject",
                "url": "https://theoriginalrubhub.es/logo192.png"
              }
            },
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://theoriginalrubhub.es/blog"
            },
            "keywords": "blog barbacoa, recetas BBQ, técnicas parrilla, ahumado carnes, rubs caseros, marinados BBQ"
          })}
        </script>
      </Helmet>

      <div className="blog-hero">
        <div className="blog-hero-overlay">
          <Container>
            <h1>Blog de Barbacoa</h1>
            <p className="hero-text">
              Descubre los secretos, técnicas y tradiciones del mundo del BBQ
            </p>
          </Container>
        </div>
      </div>

      <Container className="blog-content">
        <Row className="justify-content-center">
          {loading ? (
            <div className="text-center">Cargando posts...</div>
          ) : (
            posts.map((post) => (
              <Col key={post.id} md={4} className="mb-4">
                <Link to={getPostRoute(post)} className="post-link">
                  <Card className="post-card h-100">
                    {(post.mainImage || post.image) ? (
                      <Card.Img 
                        variant="top" 
                        src={(post.mainImage || post.image).url} 
                        alt={post.title}
                        className="post-image" 
                      />
                    ) : (
                      <div className="post-placeholder-image">
                        <span>BBQ</span>
                      </div>
                    )}
                    <Card.Body>
                      <Card.Title>{post.title}</Card.Title>
                      <div className="post-meta">
                        <span className="post-date">
                          {formatDate(post.publishDate)}
                        </span>
                        <span className="post-type">
                          {post.collection === 'recipes' ? 'Receta' : 'Consejo'}
                        </span>
                      </div>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))
          )}
        </Row>

        <section className="newsletter-section mt-5">
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="newsletter-container text-center">
                <h3>¿Quieres más contenido sobre BBQ?</h3>
                <p>Suscríbete a nuestro newsletter y recibe las últimas actualizaciones</p>
                
                {message && (
                  <Alert variant={messageType} onClose={() => setMessage('')} dismissible>
                    {message}
                  </Alert>
                )}
                
                <form className="newsletter-form" onSubmit={handleSubmit}>
                  <input 
                    type="email" 
                    placeholder="Tu correo electrónico" 
                    required 
                    value={email}
                    onChange={handleEmailChange}
                    maxLength={100}
                  />
                  <button 
                    type="submit" 
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Procesando...' : 'Suscribirse'}
                  </button>
                </form>
              </div>
            </Col>
          </Row>
        </section>
      </Container>
    </>
  );
};

export default Blog;